import { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom';
import { todayIs } from '../../helpers/functions'
import { Context } from '../../helpers/context';

const Trade = ({ data, type }) => {
  useEffect(() => {
    setRow(data)
  }, [data])

  const [load, setLoad] = useState(false)
  const [row, setRow] = useState([])
  const [user, setUser] = useContext(Context)


  return (
    <>
      {(row.length > 0) && <>
        <div className={"table-responsive " + type}>

          {
            type === 'history' ?
              <table class="front_table table table-hover table-striped left_table">
                <thead>
                  <tr>
                    <th>Trade</th>
                    <th>Entry Date</th>
                    <th>Exit Date</th>
                    <th>Performance (%)</th>
                    <th>Post</th>
                  </tr>
                </thead>
                <tbody>
                  {row.map((item, index) => {
                    const tradeObj = JSON.parse(item.tradeObj)
                    const keys = Object.keys(tradeObj)
                    return keys.map(t => {

                      return (
                        <tr key={item.id}>
                          <td className={!user.userId && "white"}>{user.userId && tradeObj[t].trade_des}</td>

                          <td>{todayIs(item.createdAt)}</td>


                          <td className={tradeObj[t].exit ? 'exit_trade' : "running_trade"}>{!tradeObj[t].exit ? 'Current' : tradeObj[t].updatedAt && todayIs(tradeObj[t].updatedAt)}</td>

                          <td className={((tradeObj[t].update || tradeObj[t].method === 'Manual') && tradeObj[t].percentage) && Math.sign(tradeObj[t].percentage) === -1 ? 'gain_percentage negetive' : 'gain_percentage'}>{(tradeObj[t].update || tradeObj[t].method === 'Manual') ? parseFloat(tradeObj[t].percentage).toFixed(2) + '%' : 'Pending'}</td>


                          <td><Link target="_blank" className="btn glory_btn" to={!user.userId ? "/signin" : "/post/" + item.id}>View Post</Link></td>
                        </tr>
                      )

                    })
                  })}
                </tbody>
              </table>

              :

              <table class="front_table table table-hover table-striped left_table table-dark">
                <thead>
                  <tr>
                    <th>Entry Date</th>
                    <th>Member</th>
                    {/* <th>Trade</th> */}
                    <th>Trade Description</th>
                    <th>Returns (%)</th>
                    <th>As of Date</th>
                    <th>View Post</th>
                  </tr>
                </thead>
                <tbody>
                  {row.map((item, index) => {
                    const tradeObj = JSON.parse(item.tradeObj)
                    const keys = Object.keys(tradeObj)
                    return keys.map(t => {
                      const isVisible = tradeObj[t].exit ? false : true
                      if (isVisible) {
                        return (
                          <tr key={item.id}>
                            <td>{todayIs(item.createdAt)}</td>


                            <td className={!user.userId && "white"}>{user.userId && item['registers.username']}</td>
                            {/* <td>{item.trade}</td> */}
                            <td className={!user.userId && "white"}>{user.userId && tradeObj[t].trade_des}</td>

                            <td className={((tradeObj[t].update || tradeObj[t].method === 'Manual') && tradeObj[t].percentage) && Math.sign(tradeObj[t].percentage) === -1 ? 'gain_percentage negetive' : 'gain_percentage'}>{(tradeObj[t].update || tradeObj[t].method === 'Manual') ? parseFloat(tradeObj[t].percentage).toFixed(2) + '%' : 'Pending'}</td>
                            <td>{tradeObj[t].updatedAt && todayIs(tradeObj[t].updatedAt)}</td>

                            <td><Link target="_blank" className="btn glory_btn" to={!user.userId ? "/signin" : "/post/" + item.id}>View Post</Link></td>
                          </tr>
                        )
                      }
                    })
                  })}
                </tbody>
              </table>
          }

        </div>
      </>
      }
    </>
  )
}

export default Trade