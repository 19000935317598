import Accordion from "../components/accordion/accordion";
import Header from "../helpers/header/header";
import { Link } from "react-router-dom";
function About() {
  return (
    <>
      <Header className="about_header">
        <div className="col-lg-9 body_col">
          <div className="about_box">
            <div className="about_banner">
              <img src="about_bg.png" alt="" />
            </div>
            <div className="about_description about_box_p">
              <p className="center"><span>About Us</span></p>
              <div className="accordion">
                <Accordion />
              </div>
            </div>
            <div className="rule about_box_p">
              <h3 className="about_h3">Points & Ranking</h3>
              <div className="rule_row">
                <div className="box"></div>
                <p>All members are ranked based on the points (e.g., upvotes) they accumulate from their posts (e.g., new trade ideas) and comments (e.g., market discussions):</p>
              </div>
              <div className="rule_row">
                <div className="box"></div>
                <p>Plus 1 point from each Upvote received</p>
              </div>
              <div className="rule_row">
                <div className="box"></div>
                <p>Minus 1 point from each Downvote received</p>
              </div>
              <div className="rule_row">
                <div className="box"></div>
                <p>Plus 20 points from each Diamond Upvote received</p>
              </div>
              <div className="rule_row">
                <div className="box"></div>
                <p>Each point expires after 180 days. This allows new members who are starting with 0 points to catch up with older members who had more time to accumulate points. It also ensures that older members have to generate new points to maintain or improve their ranking
                </p>
              </div>
              <div className="rule_row">
                <div className="box"></div>
                <p>Member Points calculation: Upvotes – Downvotes + Diamond Upvotes – Expired points</p>
              </div>
            </div>

            <div className="about_description">
              <p>Be thoughtful and honest with your Upvotes and Downvotes. Remember, the goal is to reward and retain members with profitable trade ideas and market insights, and <span className="bold">filter out the imposters!</span> </p>
              <p>If you find a post or comment to be particularly insightful or helpful, reward the member with a Diamond Upvote. You can Diamond Upvote only once every 24 hours, so use it wisely!</p>
              <p className="web_tag"><span className="bold">#Global Network</span><span className="bold"> #Market Timing</span> <span className="bold"> #Stock Analysis</span></p>
              <p>Have a question? Drop us an email at <span><a className="contact_email" href="mailto:admin@dailymarketexchange.com">admin@dailymarketexchange.com</a></span></p>

            </div>
            <div className="rule about_box_p">
              <h3 className="about_h3">Club Rules</h3>
              <div className="rule_row">
                <div className="box"></div>
                <p>The Daily Market Exchange is a professional environment. Members should conduct themselves as such</p>
              </div>
              <div className="rule_row">
                <div className="box"></div>
                <p>This is not a place for memes or jokes</p>
              </div>
              <div className="rule_row">
                <div className="box"></div>
                <p>The Daily Market Exchange is a forum of elites, for elites, by elites, to discuss market opportunities and ideas</p>
              </div>
              <div className="rule_row">
                <div className="box"></div>
                <p>This is a global network, with members from different geographies, backgrounds, and languages. Members should be mindful of that</p>
              </div>
              <div className="rule_row">
                <div className="box"></div>
                <p>All posts and comments should be thoughtful, insightful, and specific to investment analysis
                </p>
              </div>
              <div className="rule_row">
                <div className="box"></div>
                <p>Do not share material non-public information on the platform or incite non-legal trading activities (E.g., stock manipulation) </p>
              </div>
              <div className="rule_row">
                <div className="box"></div>
                <p>Members will take full accountability and ownership of their investment decisions, even if said decision was inspired by discussions on the platform. You are responsible for the risk you take and the outcome of your investments</p>
              </div>
              <div className="rule_row">
                <div className="box"></div>
                <p>The content and information on the Daily Market Exchange webpage is not intended as, and shall not be understood or construed, as financial advice</p>
              </div>
              <div className="rule_row">
                <div className="box"></div>
                <p>Do not redistribute content, including but not limited to, stock analysis, market insights, charts, internal polling results, posts, comments, or discussions, from the Daily Market Exchange website</p>
              </div>
              <div className="rule_row">
                <div className="box"></div>
                <p>Members that violate the Club Rules will be warned and censored, repeat offenders will be banned</p>
              </div>
            </div>

            <h3 className="about_h3">Membership Plans</h3>
            <div className="member_box">
              <div className="member_box_item box2">
                <div className="member">
                  <h4>Newsletter Only (Free)</h4>
                  <div className="memeber_img">
                    <div className="img">
                      <img src="about_light.png" alt="" />
                    </div>
                    <div className="img">
                      <img src="hand2.png" alt="" />
                    </div>

                  </div>
                </div>
                <div className="member_decription">
                  <div className="member_decription_row">
                    <div className="img">
                      <img src="okay_orange.png" alt="" />
                    </div>
                    <div className="content">
                      <p>Receive our biweekly E-newsletters</p>
                    </div>
                    <div className="member_decription_row">
                      <div className="img">
                        <img src="okay_orange.png" alt="" />
                      </div>
                      <div className="content">
                        <p>Benefit from our market insights and investment ideas</p>
                      </div>
                    </div>
                    <div className="member_decription_row">
                      <div className="img">
                        <img src="okay_orange.png" alt="" />
                      </div>
                      <div className="content">
                        <p>100% Free</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="member_button first">
                  <button><Link to="https://magic.beehiiv.com/v1/a019b847-d095-45ec-9786-169a2a0f508e?email={{email}}">Start Receiving Our Trade Ideas Today</Link>
                  </button>
                </div>
              </div>

              <div className="member_box_item">
                <div className="member">
                  <h4>Full Access ($49/Month)</h4>
                  <div className="memeber_img">
                    <div className="img">
                      <img src="about_light.png" alt="" />
                    </div>
                    <div className="img">
                      <img src="hand1.png" alt="" />
                    </div>

                  </div>
                </div>

                <div className="member_decription">
                  <div className="member_decription_row">
                    <div className="img">
                      <img src="okay.png" alt="" />
                    </div>
                    <div className="content">
                      <p>For users looking to join and participate in our club</p>
                    </div>
                  </div>
                  <div className="member_decription_row">
                    <div className="img">
                      <img src="okay.png" alt="" />
                    </div>
                    <div className="content">
                      <p>Full access to all discussions on the platform</p>
                    </div>
                  </div>
                  <div className="member_decription_row">
                    <div className="img">
                      <img src="okay.png" alt="" />
                    </div>
                    <div className="content">
                      <p>View all our Current Trades </p>
                    </div>
                  </div>
                </div>
                <div className="member_button">
                  <button><Link to="/singup">Join Today</Link></button>
                </div>
              </div>
              {/* <div className="member_box_item box2">
                <div className="member">
                  <h4>Executive Club Member ($149/Month)</h4>
                  <div className="memeber_img">
                    <div className="img">
                      <img src="about_light.png" alt="" />
                    </div>
                    <div className="img">
                      <img src="hand2.png" alt="" />
                    </div>

                  </div>
                </div>
                <div className="member_decription">
                  <div className="member_decription_row">
                    <div className="img">
                      <img src="okay_orange.png" alt="" />
                    </div>
                    <div className="content">
                      <p>Access to our private virtual Cigar Lounge</p>
                    </div>
                    <div className="member_decription_row">
                      <div className="img">
                        <img src="okay_orange.png" alt="" />
                      </div>
                      <div className="content">
                        <p>For members with the best track record for trade ideas and market insights </p>
                      </div>
                    </div>
                    <div className="member_decription_row">
                      <div className="img">
                        <img src="okay_orange.png" alt="" />
                      </div>
                      <div className="content">
                        <p>Invite-only</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="member_button">
                  <button><Link to="/singup">Join Our Network Today</Link>
                  </button>
                </div>
              </div> */}

            </div>

            <div className="about_bottom  bold about_description">
              <p className="">Please be advised, we do not offer refunds. Our membership plans are monthly recurring subscriptions that you can cancel at any time.</p>
            </div>
          </div>
        </div>
      </Header>
    </>
  );
}

export default About;