
import { useState, useRef, useEffect, useContext, useMemo, useCallback } from 'react'
import axios from 'axios'
import DateTimePicker from 'react-datetime';
import { url } from '../../helpers/url'
import { Context } from '../../helpers/context'
import { Link } from 'react-router-dom';
import { feedTime, substring } from '../../helpers/functions'
import ReactHtmlParser from "react-html-parser"
import { socket } from "../../helpers/socket"

function Model({ data, sendPost, edit, type }) {
  const ref = useRef(null)
  const [user, setUser] = useContext(Context)
  const [model, setModel] = useState('block')
  const [select, setSelect] = useState('Post')
  const [retweet, setRetweet] = useState()

  useEffect(() => {
    if (user.modelRetweet) {
      setRetweet(user.retweetObj)
    }
  }, [user.retweetObj])

  const modelHandler = (e) => {
    if (ref && !ref.current.contains(e.target)) {
      setUser({ ...user, model: false, retweetObj: false, loginPopup: false })
    }
  }

  const modelCloseHandler = (e) => {
    setUser({ ...user, model: false, retweetObj: false, loginPopup: false })
  }

  const onSelect = (e) => {
    setSelect(e.target.value)
  }
  const imgRef = useRef(null)
  const openImg = () => {
    imgRef.current.click()
  }
  const [image, setImage] = useState([])
  const imageHandler = (e) => {
    // setImage(e.target.files)
    setImage(Array.from(e.target.files))
  }
  const [value, setValue] = useState({
    title: '',
    content: '',
    tags: '',
    end_time: new Date()
  })

  // useEffect(() => {
  //   if (edit.id && edit.type === "Post") {
  //     setSelect(edit.type)
  //     setValue({ ...value, ...edit })
  //   }
  //   if (edit.id && edit.type === "Polls") {
  //     setSelect(edit.type)

  //   }
  // }, [edit])

  const dynamicText = (content, id) => {
    if (content && content.length > 500) {
      return substring(content, 500) + " <a href='" + '/profile/' + id + "' target='_blank'>read more</a>"
    }
    return content
  }


  const [timer, setTimer] = useState(null);

  const [commentText, setCommentText] = useState({})

  const [searchList, setSearchList] = useState([])


  const [textarearow, setTextarearow] = useState(150)
  const [position, setPosition] = useState(-290)

  const textareaRef = useRef(null)

  const textareaHandler = (e) => {

    var rect = document.querySelector('.content_edit').getBoundingClientRect();
    const height = window.innerHeight
    const curPosition = rect.top + 150


    if (curPosition > height) {
      setPosition(65)
    }

    setSearchList([])
    const value = e.currentTarget.textContent
    const total = textareaRef.current.scrollHeight

    if (value.length === 0) {
      document.querySelector('.content_edit').innerHTML = ''
    }
    // it is for row increasing 
    if (total > 150) {
      setTextarearow(total)
    }

    // it is for mention
    if (value.includes('@')) {
      const split = value.split("@")
      const search = split.pop()
      if (timer) {
        clearTimeout(timer);
        setTimer(null);
      }
      setTimer(
        setTimeout(async () => {
          if (search.trim()) {
            axios.post(url + `/user/search?page=0`, { search: search })
              .then(({ data }) => {
                if (data.success) {
                  setSearchList(data.data)
                }
              })
          }
        }, 1000)
      );
    }
  }


  const onType = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value })
  }



  const [selectUser, setSelectUser] = useState({})
  const searchClickHandler = (item, type) => {

    // const newComment = searchSplit.join('') + `<a target="_blank" href="/profile/${item.id}">${item.username}</a>`

    const query = document.querySelector('.content_edit')
    const content = document.querySelector('.content_edit').innerHTML
    let split = content.split('@')
    const lastword = "@" + split[split.length - 1]
    const regex = new RegExp(lastword + "$");
    const newString = content.replace(regex, " ");
    const userLink = `<a target="_blank" href="/profile/${item.id}">${item.username}</a>` + "&nbsp;"
    query.innerHTML = newString + userLink

    let sel = window.getSelection();
    sel.selectAllChildren(query);
    sel.collapseToEnd()
    setSearchList([])
    setSelectUser({ ...selectUser, [item.id]: item.id })
  }



  const [date, setDate] = useState(new Date());

  const dateHandler = (curDate) => {
    if (curDate._d) {
      setValue({ ...value, end_time: curDate._d })
    }
  }



  const onPost = (e) => {
    e.preventDefault()

    const formData = new FormData()
    formData.append('category', 'create')
    formData.append('type', select)
    if (select === 'Polls') {
      formData.append('title', value.title)
      formData.append('choice1', value.choice1)
      formData.append('choice2', value.choice2)
      formData.append('tags', value.tags)
      formData.append('end_time', value.end_time)
    } else {
      formData.append('title', value.title)
      const query = document.querySelector('.content_edit').innerHTML
      if (query) {
        formData.append('content', query)
      }
      if (value.tags) {
        formData.append('tags', value.tags.replace(/,\s*$/, ""))
      }

      image.map(item => formData.append('image', item))
      if (retweet) {
        formData.append('retweet', JSON.stringify(retweet))
      }
    }


    axios.post(url + '/post/', formData)
      .then((result) => {
        if (result.data.success) {
          let retweetData = {}
          if (retweet) {
            retweetData['registers1.username'] = retweet['registers.username']
            retweetData['registers1.name'] = retweet['registers.name']
            retweetData['registers1.image'] = retweet['registers.image']
            retweetData['mainUserId'] = retweet.userId
            retweetData['retweetId'] = retweet.id
          }
          const newPost = { ...result.data.post, ['registers.username']: user.username, ['registers.name']: user.name, ['registers.image']: user.image, userId: user.userId, ...retweetData }
          setValue({})
          setUser({ ...user, model: false, retweetObj: false, newPost: newPost })

          if (select !== 'Polls') {
            const query = document.querySelector('.content_edit').innerHTML
            let notificationId = {}
            const array = Object.keys(selectUser)
            for (let i = 0; i < array.length; i++) {
              const text = `href="/profile/${array[i]}"`
              if (query.includes(text)) notificationId = { ...notificationId, [array[i]]: true }
            }

            const notificationKeys = Object.keys(notificationId)

            if (notificationKeys.length > 0) {
              const item = result.data.post
              notificationKeys.map(key => {
                const parseKey = parseInt(key)
                if (user.userId !== parseKey) {
                  socket.emit("sendMessage", { postId: item.id, title: item.title, type: "post", receiverId: parseKey, mainUserId: user.userId })
                }
              })
            }
          }

        }
      })

  }


  return (
    <div className="model" style={{ display: model }} onClick={modelHandler} >
      {type === 'login' ?
        <div className="form_main login_popup">
          <div className="form" ref={ref}>
            <div className="form_header">
              <i className="fa fa-close" onClick={modelCloseHandler}></i>
            </div>
            <div className='text-center'><Link to="/signin">Login </Link>to see more posts</div>
          </div>
        </div>
        :
        type === 'read_more' ?
          <div className="form_main login_popup">
            <div className="form" ref={ref}>
              <div className="form_header">
                <i className="fa fa-close" onClick={modelCloseHandler}></i>
              </div>
              <div className='text-center'><Link to="/signin">Login </Link>to read the post</div>
            </div>
          </div>
          :

          <div className="form_main">
            <div className="form" ref={ref}>
              <form onSubmit={onPost}>
                <div className="form_header">
                  <p>Post a New Idea</p>
                  <i className="fa fa-close" onClick={modelCloseHandler}></i>
                </div>

                {select === 'Polls' && <>
                  <div className="input_items">
                    <label htmlFor="question">Question</label>
                    <input onChange={onType} id="question" name='title' type="text" />
                  </div>
                  <div className="input_items">
                    <label htmlFor="choice1">Choice 1</label>
                    <input onChange={onType} id="choice1" name='choice1' type="text" />
                  </div>
                  <div className="input_items">
                    <label htmlFor="choice2">Choice 2</label>
                    <input onChange={onType} id="choice2" name='choice2' type="text" />
                  </div>
                  <div className="input_items">
                    <label htmlFor="Title">Add Tags (Use comma for multiple tags) </label>
                    <input name='tags' onChange={onType} id="Title" type="text" />
                  </div>
                  <div className="input_items">
                    <label htmlFor="Title">End Time</label>
                    {/* <input name='end_time' onChange={onType} id="Title" type="date" /> */}
                    <DateTimePicker inputProps={{ name: "end_time" }} name="end_time" onChange={dateHandler} value={value.end_time} />
                  </div>
                </>}
                {(select === 'Post' || select === 'Image') && <>
                  <div className="input_items">
                    <label htmlFor="Title">Title</label>
                    <input name='title' onChange={onType} id="Title" type="text" value={value['title']} />
                  </div>
                  <div className="input_items">
                    <label htmlFor="Title">Add Tags (Use comma for multiple tags) </label>
                    <input name='tags' onChange={onType} id="Title" type="text" value={value['tags']} />
                  </div>

                  <div className="input_items input_items_description">
                    {searchList.length > 0 &&
                      <div className="search_user_list global_scroll"
                        style={{
                          bottom: position + 'px'
                        }}>
                        {/* [4, 5, 6, 7, 8, 9] */}
                        {searchList.map(item => {
                          return (
                            <div className="user_item" onClick={() => searchClickHandler(item, 'comment')}>
                              <div className="user_img">
                                <img src={item.image ? item.image : '/profile.png'} alt="" />
                              </div>
                              <div className="user_name">
                                {item.username}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    }
                    <label htmlFor="Title">Description</label>
                    <div
                      className={textarearow > 285 ? "content_edit data-placeholder global_scroll" : "content_edit data-placeholder hoverflow"}
                      contentEditable style={{
                        height: textarearow < 285 ? textarearow : 300 + "px",
                      }} placeholder="Write your description"
                      onInput={textareaHandler}
                      data-placeholder="Write your description"
                      ref={textareaRef}>
                    </div>
                    <p className="limit">Max 800 words/characters</p>
                  </div>
                  {/* <div className="input_items">
                    <label htmlFor="Title">Description</label>
                    <textarea style={{ height: textarea < 300 ? textarea : 300 + "px" }} ref={textareaRef} name='content' onChange={textareaHandler} value={value['content']} className={textarea > 320 ? "global_scroll w-100" : "w-100 hoverflow"}></textarea>
                    <p className="limit">Max 800 words/characters</p>
                  </div> */}




                  {(select === 'Image') &&
                    <div className="input_items">
                      <label htmlFor="Title">Upload Image</label>
                      <input ref={imgRef} type="file" multiple name="" id="" onChange={imageHandler} />
                      <div className="img_box" onClick={openImg}>
                        <i className="fa fa-image"></i>
                      </div>
                      {image.map((item) => {

                        return (
                          <div key={item} className="img_box" onClick={openImg}>
                            <img src={URL.createObjectURL(item)} alt="" />
                          </div>
                        )
                      })

                      }
                    </div>
                  }
                </>}
                <div className="input_items">
                  <label htmlFor="Title">Post Type</label>
                  <select name="type" id="" onChange={onSelect}>
                    <option selected={select === 'Post'} value="Post">Post</option>
                    {!retweet && <option selected={select === 'Polls'} value="Polls">Polls</option>}
                    <option selected={select === 'Image'} value="Image">Image</option>
                  </select>
                </div>

                {retweet &&
                  <div className="retweet_model">
                    <div className="retweet_user">
                      <div className="img">
                        <Link to={"/profile/" + retweet.userId}>
                          <img src={retweet['registers.image'] ? retweet['registers.image'] : "/profile.png"} alt="" />
                        </Link>
                      </div>
                      <div className="name">
                        <Link to={"/profile/" + retweet.userId}>
                          <p>{retweet['registers.username'] ? retweet['registers.username'] : 'Unknown'} · </p>
                        </Link>
                      </div>
                      <div className="time">
                        <p>{feedTime(retweet.createdAt, 'ago')}</p>
                      </div>
                    </div>

                    <div className="retweet_content">

                      <div className="img">
                        {retweet.image && JSON.parse(retweet.image).map(item => {
                          return (

                            <Link to={url + '/uploads/' + item.filename} target="_blank" rel="noopener noreferrer">
                              <img src={url + '/uploads/' + item.filename} alt="" />
                            </Link>

                          )
                        })
                        }
                      </div>

                      {retweet.title &&
                        <div className="title">
                          <h2>{retweet.title}
                          </h2>
                        </div>
                      }

                      {retweet.content &&
                        <div className="description">
                          {ReactHtmlParser(dynamicText(retweet.content, retweet.id))}
                        </div>
                      }
                    </div>
                  </div>
                }


                <div className="form_btn">
                  <button>{retweet ? 'Retweet' : 'Post'}</button>
                </div>
              </form>
            </div>
          </div>

      }
    </div>
  );
}

export default Model;