import { useState, useEffect, useRef } from 'react'
import Layout from '../../../admin-component/layout'
import { url } from '../../../helpers/url'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
function CouponCom({ mode }) {

  const stockRef = useRef()
  const stockRef2 = useRef()
  useEffect(() => {
    // below it for search result if click outside 
    const stockClickOutside = (e) => {
      if (stockRef && stockRef.current && !stockRef.current.contains(e.target)) {
        setStockResult([])
        setFound(false)
      }
    };

    const stockClickOutside2 = (e) => {
      if (stockRef2 && stockRef2.current && !stockRef2.current.contains(e.target)) {
        setStockResult2([])
        setFound2(false)
      }
    };

    window.addEventListener("click", stockClickOutside);
    window.addEventListener("click", stockClickOutside2);
    return () => {
      window.removeEventListener("click", stockClickOutside, stockClickOutside2);
    }
  }, [])

  const { id } = useParams()
  const [cat, setCat] = useState({})
  const [load, setLoad] = useState(false)

  useEffect(() => {
    if (id) {
      axios.get(`${url}/post/single?postId=${id}`).then(({ data }) => {
        if (data.success && data.rows.length > 0) {
          setCat(data.rows[0])
          const tradeItem = data.rows[0]
          if (tradeItem.tradeObj) {
            const parse = JSON.parse(tradeItem.tradeObj)
            const keys = Object.keys(parse)
            setTradeItem(parse)
            setTotalbox(keys.length > 0 ? Math.max(...keys) : 0)
            setTradebox(keys)
          }
          setLoad(true)
        }
      })
    }
  }, [id])


  // Stock 1

  const [timer, setTimer] = useState()
  const [stockResult, setStockResult] = useState([])
  const [found, setFound] = useState(false)
  const [currentResult, setCurrentResult] = useState('')
  const stockHandler = (e, item) => {
    let updateItem = { ...tradeItem[item] }
    if (updateItem["symbol"]) {
      updateItem["symbol"] = ''
      setTradeItem({ ...tradeItem, [item]: updateItem })
    }

    const search = e.target.value.trim()
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(async () => {
        if (search) {
          setCurrentResult(item)
          axios.get(url + `/admin/stock/search?search=${search}`).then(({ data }) => {
            if (data.success && data.data) {
              if (data.data.length === 0) {
                setFound(true)
              } else {
                setFound(false)
                setStockResult(data.data)
              }
            }
          })
        }
      }, 1000)
    );
  };


  const stockClickHandler = (res, item) => {
    let updateItem = { ...tradeItem[item] }
    updateItem["symbol"] = { name: res.name, symbol: res.symbol, exchange: res.exchange }
    updateItem["error"] = false
    setTradeItem({ ...tradeItem, [item]: updateItem })
    setStockResult([])
  }


  // Stock 2

  const [timer2, setTimer2] = useState()
  const [stockResult2, setStockResult2] = useState([])
  const [found2, setFound2] = useState(false)
  const [currentResult2, setCurrentResult2] = useState('')
  const stockHandler2 = (e, item) => {
    let updateItem = { ...tradeItem[item] }
    if (updateItem["symbol2"]) {
      updateItem["symbol2"] = ''
      setTradeItem({ ...tradeItem, [item]: updateItem })
    }

    const search = e.target.value.trim()
    if (timer2) {
      clearTimeout(timer2);
      setTimer2(null);
    }
    setTimer2(
      setTimeout(async () => {
        if (search) {
          setCurrentResult2(item)
          axios.get(url + `/admin/stock/search?search=${search}`).then(({ data }) => {
            if (data.success && data.data) {
              if (data.data.length === 0) {
                setFound2(true)
              } else {
                setFound2(false)
                setStockResult2(data.data)
              }
            }
          })
        }
      }, 1000)
    );
  };


  const stockClickHandler2 = (res, item) => {
    let updateItem = { ...tradeItem[item] }
    updateItem["symbol2"] = { name: res.name, symbol: res.symbol, exchange: res.exchange }
    updateItem["error2"] = false
    setTradeItem({ ...tradeItem, [item]: updateItem })
    setStockResult2([])
  }

  // Stock 2 end 


  const [success, setSuccess] = useState(false)


  const [totalbox, setTotalbox] = useState(0)
  const [tradebox, setTradebox] = useState([])
  const [tradeItem, setTradeItem] = useState({})

  const addTradeBox = () => {
    const copyBox = [...tradebox]
    const total = totalbox + 1
    setTradeItem({ ...tradeItem, [total]: { trade_type: 'Long Trade', method: 'Dynamic', symbol: '', symbol2: '', trade_des: '', error: false, error2: false, entry_1: 0, entry_2: 0, percentage: 0, updateAt: new Date(), createdAt: new Date() } })
    copyBox.push(total)
    setTradebox(copyBox)
    setTotalbox(total)

  }

  const removeTradebox = (rm) => {
    const copyBox = tradebox.filter(item => item !== rm)
    const copyTradeItem = { ...tradeItem }
    delete copyTradeItem[rm]
    setTradeItem(copyTradeItem)
    setTradebox(copyBox)
  }


  const onType = (e, item) => {
    let updateItem = { ...tradeItem[item] }

    updateItem[e.target.name] = e.target.value
    if (updateItem.method === 'Manual') {
      updateItem["updatedAt"] = new Date()
    }
    setTradeItem({ ...tradeItem, [item]: updateItem })
  }

  const onCheckbox = (e, item) => {
    let updateItem = { ...tradeItem[item] }
    updateItem[e.target.name] = updateItem[e.target.name] ? false : true
    setTradeItem({ ...tradeItem, [item]: updateItem })
  }

  const methodHandler = (e, item) => {
    const value = e.target.value
    let updateItem = { ...tradeItem }

    if (cat.tradeObj) {
      const parse = JSON.parse(cat.tradeObj)
      if (parse[item]) {
        if (parse[item].method === value) {
          updateItem[item] = parse[item]
        } else {
          updateItem[item] = { trade_type: 'Long Trade', method: value, symbol: '', symbol2: '', trade_des: '', error: false, error2: false, entry_1: 0, entry_2: 0, percentage: 0 }
        }
      } else {
        updateItem[item] = { ...updateItem[item], method: value }
      }
    }
    else {
      updateItem[item] = { ...updateItem[item], method: value }
    }
    setTradeItem(updateItem)
  }



  const formHandler = (e) => {
    e.preventDefault()
    const keys = Object.keys(tradeItem)
    let copyTradeItem = { ...tradeItem }
    let error = false
    keys.map(item => {
      if (!copyTradeItem[item].symbol) {
        copyTradeItem[item].error = true
        error = true
      }
      if (copyTradeItem[item].trade_type === 'Pair Trade' && !copyTradeItem[item].symbol2) {
        copyTradeItem[item].error2 = true
        error = true
      }
    })

    if (error) {
      setTradeItem(copyTradeItem)
    } else {
      const data = new FormData()
      data.append("trade", keys.length > 0 ? JSON.stringify(copyTradeItem) : '')
      data.append("id", cat.id)
      data.append("mode", "trade")
      axios.post(`${url}/admin/post/`, data).then(({ data }) => {
        if (data.success) {
          setSuccess(true)
        }
      })
    }

  }


  return (
    <Layout extraClass="user_post_edit_admin">
      <div className="admin_form admin_form_user_post_edit">
        <h3 className='heading mb-2'>Trade Post</h3>

        {(success) &&
          <div className="alert alert-success">Trade post has been updated</div>
        }

        {(!success && load) &&
          <form onSubmit={formHandler} autoSave='off' autoComplete='off'>

            {tradebox.map(item => {
              return (
                <div className="input_items trade_box" key={item} id={"tradebox_" + item}>

                  <div className="input_group first_input_group">
                    <i className="fa fa-close" onClick={() => removeTradebox(item)}></i>
                    <label htmlFor="">Method</label>
                    <select name="method" id="" onChange={(e) => methodHandler(e, item)}>
                      <option selected={tradeItem[item].method === 'Dynamic'} value="Dynamic">Dynamic</option>
                      <option selected={tradeItem[item].method === 'Manual'} value="Manual">Manual</option>
                    </select>
                  </div>


                  <div className="input_group first_input_group">
                    <label htmlFor="">Type of trades</label>
                    <select name="trade_type" id="" onChange={(e) => onType(e, item)}>
                      <option selected={tradeItem[item].trade_type === 'Long Trade'} value="Long Trade">Long Trade</option>
                      <option selected={tradeItem[item].trade_type === 'Short Trade'} value="Short Trade">Short Trade</option>
                      <option selected={tradeItem[item].trade_type === 'Pair Trade'} value="Pair Trade">Pair Trade</option>
                    </select>
                  </div>




                  <div className="input_group">
                    <label htmlFor="">{tradeItem[item].trade_type === 'Pair Trade' ? 'Long Entry' : tradeItem[item].trade_type === 'Long Trade' ? 'Long Entry' : 'Short Entry'}</label>
                    <input onChange={(e) => onType(e, item)} type="number" name={"entry_1"} id="" value={tradeItem[item].entry_1} min={1} />
                  </div>



                  {tradeItem[item].method === 'Manual' && <>
                    <div className="input_group select_stock">
                      <label htmlFor="">Stock Name</label>
                      <input onChange={(e) => onType(e, item)} required type="text" name="symbol" id="" value={tradeItem[item].symbol} />
                    </div>
                    {tradeItem[item].error &&
                      <div className="alert alert-danger">
                        Stock symbol is required
                      </div>
                    }
                  </>
                  }


                  {tradeItem[item].method === 'Dynamic' &&
                    <div className="input_group select_stock">
                      <label htmlFor="">Select Stock</label>
                      <input onChange={(e) => stockHandler(e, item)} type="text" name="symbol" id="" value={tradeItem[item].symbol.name} />

                      <div className="result_item_wrapper" ref={stockRef}>
                        {(stockResult.length > 0 && !found && currentResult === item) &&
                          <div className="result_item">
                            {stockResult.map(res => (
                              <div className="item" onClick={() => stockClickHandler(res, item)}>
                                <p>{res.name}</p>
                                <p>Symbol: {res.symbol}</p>
                              </div>
                            ))}
                          </div>
                        }
                        {(stockResult.length === 0 && found && currentResult === item) &&
                          <div className="result_item">
                            <div className="item">
                              <p>Not found</p>
                            </div>
                          </div>
                        }
                      </div>
                      {tradeItem[item].error &&
                        <div className="alert alert-danger">
                          Stock symbol is required
                        </div>
                      }
                    </div>
                  }

                  {tradeItem[item].trade_type === 'Pair Trade' && (
                    <>
                      <div className="input_group">
                        <label htmlFor="">Short Entry</label>
                        <input onChange={(e) => onType(e, item)} type="number" name={"entry_2"} id="" value={tradeItem[item].entry_2} min={1} />
                      </div>

                      {tradeItem[item].method === 'Manual' && <>
                        <div className="input_group select_stock">
                          <label htmlFor="">Stock Name</label>
                          <input onChange={(e) => onType(e, item)} required type="text" name="symbol2" id="" value={tradeItem[item].symbol2} />
                        </div>
                        {tradeItem[item].error2 &&
                          <div className="alert alert-danger">
                            Stock symbol is required
                          </div>
                        }

                      </>
                      }

                      {tradeItem[item].method === 'Dynamic' &&
                        <div className="input_group select_stock">
                          <label htmlFor="">Select Stock</label>
                          <input onChange={(e) => stockHandler2(e, item)} type="text" name="symbol2" id="" value={tradeItem[item].symbol2.name} />

                          <div className="result_item_wrapper" ref={stockRef2}>
                            {(stockResult2.length > 0 && !found2 && currentResult2 === item) &&
                              <div className="result_item">
                                {stockResult2.map(res => (
                                  <div className="item" onClick={() => stockClickHandler2(res, item)}>
                                    <p>{res.name}</p>
                                    <p>Symbol: {res.symbol}</p>
                                  </div>
                                ))}
                              </div>
                            }
                            {(stockResult2.length === 0 && found2 && currentResult2 === item) &&
                              <div className="result_item">
                                <div className="item">
                                  <p>Not found</p>
                                </div>
                              </div>
                            }
                          </div>
                          {tradeItem[item].error2 &&
                            <div className="alert alert-danger">
                              Stock symbol is required
                            </div>
                          }
                        </div>
                      }

                    </>
                  )}

                  {tradeItem[item].method === 'Manual' && <>
                    <div className="input_group select_stock">
                      <label htmlFor="">Percentage</label>
                      <input onChange={(e) => onType(e, item)} required type="number" name="percentage" id="" value={tradeItem[item].percentage} />
                    </div>
                  </>
                  }

                  <div className="input_group">
                    <label htmlFor="">Description</label>
                    <textarea onChange={(e) => onType(e, item)} name="trade_des" id="" value={tradeItem[item].trade_des} ></textarea>
                  </div>

                  <div className="input_group">
                    <Link target="__blank" className='btn btn-success' to={"/post/" + cat.id}><span className='capitalize'>View Post</span> </Link>
                  </div>

                  <div className="exit_trade">
                    {/*  */}
                    <input checked={tradeItem[item].exit} type="checkbox" name="exit" id={"exit-" + item} onChange={(e) => onCheckbox(e, item)} />
                    <label htmlFor={"exit-" + item}>Exit Trade</label>
                  </div>

                </div>

              )
            })}

            <div className="input_items">
              <div className="input_group">
                <button className='admin_btn' type='button' style={{ backgroundColor: 'green' }} onClick={addTradeBox}>Add Trade Box</button>
              </div>

              <div className="input_group">
                <button type='submit' className='admin_btn'>Make Trade Post</button>
              </div>


            </div>


          </form>
        }
      </div>
    </Layout >
  );
}

export default CouponCom;