
import { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import { Context } from "../../helpers/context"
import { socket } from "../../helpers/socket"
import { url } from '../url';
import { useLocation, Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { feedTime } from '../functions';
function Header({ children, className }) {
  const [mobile, setMobile] = useState(false)
  const [bar, setBar] = useState(false)
  const showMenu = () => {
    const curMobile = !mobile
    setMobile(!mobile)
  }
  const router = useLocation()
  const ref = useRef(null)
  const [user, setUser] = useContext(Context)
  const [tags, setTags] = useState([])
  const [load, setLoad] = useState(false)

  useEffect(() => {
    axios.get(url + '/front/')
      .then(({ data }) => {
        if (data.success && data.tags && data.tags.tags) {
          setTags(data.tags.tags.split(','))
        }
      })
    axios.get(url + '/auth/')
      .then(({ data }) => {
        if (data.success) {
          setUser(data)
        }
      })
  }, [])
  const [width, setWidth] = useState()
  useEffect(() => {
    setWidth(window.screen.width)
    if (window.screen.width > 991) {
      setMobile(true)
      setBar(true)
    } else {
      window.addEventListener('click', (e) => {
        if (ref.current && e.target.className !== 'fa fa-bars' && !ref.current.contains(e.target)) {
          setMobile(false)
        }
      })
    }
  }, [])

  const [search, setSearch] = useState(false)
  const [searchData, setSearchData] = useState()
  const inputHandler = (e) => {
    if (e.target.value) {
      setSearchData(e.target.value)
      setSearch(true)
    } else {
      setSearch(false)
    }
  }
  const logout = () => {
    localStorage.removeItem('jay.com')
    setUser({})
    window.location.href = '/signin'

  }




  const searchHandler = (e) => {
    if (searchData) {
      const join = searchData.split(' ').join('-')
      window.location.href = '/tags/' + join
    }
  }


  // Socket io start
  const [notificationLength, setNotificationLength] = useState(0)
  const [loginId, setLoginId] = useState(user.userId)
  const [notification, setNotification] = useState([])

  useEffect(() => {
    axios.get(url + '/notification?pgae=0')
      .then(({ data }) => {
        if (data.success) {
          setNotification(data.rows)
          const seen = data.rows.filter(item => !item.seen)
          setNotificationLength(seen.length)
          setLoad(true)
        }
      })
  }, [])

  useEffect(() => {
    if (user.userId) {
      socket.emit("addUser", user.userId)
    }

  }, [user.userId])
  const [arrival, setArrival] = useState()
  useEffect(() => {
    socket.on("getMessage", (data) => {

      if (data) {
        setArrival(data)
      }
    })
    // return () => {
    //   socket.disconnect();
    // }
  }, [])

  useEffect(() => {
    if (arrival) {
      console.log(arrival)
      const index = notification.findIndex(item => item.id === arrival.id)
      if (index !== -1) {
        const copyArray = [...notification]
        copyArray[index] = arrival
        const splice = copyArray.splice(index, 1)[0]
        copyArray.splice(0, 0, splice)
        setNotification(copyArray)
      } else {
        setNotification([arrival, ...notification])
      }
      if (!openNotification) {
        setNotificationLength(notificationLength + 1)
      }

    }
  }, [arrival])

  // Socket io end


  const [hasMore, setHasMore] = useState(true)
  const findItem = () => {
    if (hasMore && notification.length === 20 && user.userId) {
      setHasMore(false)
      axios.get(`${url}/notification?offset=` + notification.length).then(({ data }) => {
        if (data.success && data.rows.length > 0) {
          setNotification([...notification, ...data.rows])
          setHasMore(true)
        }
        if (data.rows.length === 0 && data.success) {
          setHasMore(false)
        }
      })
    }
  }

  const [openNotification, setOpenNotification] = useState(false)

  const notificationHandler = () => {
    if (user.userId) {
      setOpenNotification(!openNotification)
      if (notificationLength) {
        axios.post(url + '/notification')
          .then(({ data }) => {
            if (data.success) {
              setNotificationLength(0)
            }
          })
      }
    } else {
      setOpenNotification(!openNotification)
    }
  }

  const notifyRef = useRef(null)


  useEffect(() => {
    const notificationClick = (e) => {
      if (notifyRef && notifyRef.current && !notifyRef.current.contains(e.target)) {
        setOpenNotification(false)
      }
    };
    window.addEventListener("click", notificationClick);
    return () => window.removeEventListener("click", notificationClick);
  }, [])

  const substring = (text) => {
    if (text > 35) {
      return text.substring(0, 35) + '...'
    } else {
      return text
    }
  }


  const dynamicComment = (content) => {
    if (content) {
      let replaceContent = content.replace(/<[^>]*>/g, ' ')
      replaceContent = replaceContent.replace(/&nbsp;/g, ' ');

      if (replaceContent.length > 20) {
        return replaceContent.substring(0, 20).trim() + '... '
      }
      return replaceContent
    }
  }


  const dynamicTxt = (item, text, custom = '') => {
    if (item.like && item.dislike) {
      return <><p><a href={"/post/" + item.postId}><span className='short_span'>{text}: </span> <span>{substring(item.title) + ` got${custom} UPVOTE +` + item.like + ' and DOWNVOTE +' + item.dislike}</span><div className="time"><p>{feedTime(item.updatedAt, 'ago')}</p></div></a></p></>
    } else if (item.like) {
      return <><p><a href={"/post/" + item.postId}><span className='short_span'>{text}: </span> <span>{substring(item.title) + ` got${custom} UPVOTE +` + item.like}</span><div className="time"><p>{feedTime(item.updatedAt, 'ago')}</p></div></a></p></>
    }
    else if (item.dislike) {
      return <><p><a href={"/post/" + item.postId}><span className='short_span'>{text}: </span> <span>{substring(item.title) + ' got DOWNVOTE +' + item.dislike}</span><div className="time"><p>{feedTime(item.updatedAt, 'ago')}</p></div></a></p></>
    }
  }


  const dynamicCmtTxt = (item, text, custom = '') => {
    let url = "/post/" + item.postId + "/#comment_id_" + item.cmtId
    if (item.type === 'reply_like' || item.type === 'reply_super_like') {
      url = "/post/" + item.postId + "/#reply_id_" + item.cmtId + "?cmt=" + item.repId
    }
    if (item.like && item.dislike) {
      return <><p><a href={url}><span className='short_span'>{text}: </span> <span>{dynamicComment(item.comment) + ` got${custom} UPVOTE +` + item.like + ' and DOWNVOTE +' + item.dislike}</span><div className="time"><p>{feedTime(item.updatedAt, 'ago')}</p></div></a></p></>
    } else if (item.like) {
      return <><p><a href={url}><span className='short_span'>{text}: </span> <span>{dynamicComment(item.comment) + ` got${custom} UPVOTE +` + item.like}</span><div className="time"><p>{feedTime(item.updatedAt, 'ago')}</p></div></a></p></>
    }
    else if (item.dislike) {
      return <><p><a href={url}><span className='short_span'>{text}: </span> <span>{dynamicComment(item.comment) + ' got DOWNVOTE +' + item.dislike}</span><div className="time"><p>{feedTime(item.updatedAt, 'ago')}</p></div></a></p></>
    }
  }


  const notificationType = (item, text) => {
    if (item.type === 'post_like') {
      return dynamicTxt(item, 'Your post')
    }
    else if (item.type === 'super_like') {
      return dynamicTxt(item, 'Your post', ' DIAMOND')
    }
    else if (item.type === 'comment') {
      return <><p><a href={"/post/" + item.postId + "/#comment_id_" + item.cmtId}><span className='short_span'>Your post: </span><span>{substring(item.title) + ' got COMMENTS+' + item.like}</span><div className="time"><p>{feedTime(item.createdAt, 'ago')}</p></div></a></p></>
    }
    else if (item.type === 'reply') {
      return <><p><a href={"/post/" + item.postId + "/#reply_id_" + item.cmtId + "?cmt=" + item.repId}><span className='short_span'>Your comment: </span> <span>{dynamicComment(item.comment) + ' got REPLIES+' + item.like}</span><div className="time">
        <p>{feedTime(item.createdAt, 'ago')}</p></div></a></p></>
    }
    else if (item.type === 'comment_mention') {
      return <><p><a href={"/post/" + item.postId + "/#comment_id_" + item.cmtId}><span className='short_span'>You were mentioned in a Comment: <span>{dynamicComment(item.comment)}</span> </span><div className="time"><p>{feedTime(item.createdAt, 'ago')}</p></div></a></p></>
    }

    else if (item.type === 'reply_mention') {
      return <><p><a href={"/post/" + item.postId + "/#reply_id_" + item.cmtId + "?cmt=" + item.repId}><span className='short_span'>You were mentioned in a Reply: <span>{dynamicComment(item.comment)}</span></span><div className="time">
        <p>{feedTime(item.createdAt, 'ago')}</p></div></a></p></>
    }
    else if (item.type === 'post') {
      return <><p><a href={"/post/" + item.postId}><span className='short_span'>You were mentioned in a Post: </span><span>{substring(item.title)}</span><div className="time">
        <p>{feedTime(item.createdAt, 'ago')}</p></div></a></p></>
    }

    else if (item.type === 'comment_like') {
      return dynamicCmtTxt(item, 'Your comment')
    }
    else if (item.type === 'reply_like') {
      return dynamicCmtTxt(item, 'Your reply')
    }
    else if (item.type === 'comment_super_like') {
      return dynamicCmtTxt(item, 'Your comment', ' DIAMOND')
    }
    else if (item.type === 'reply_super_like') {
      return dynamicCmtTxt(item, 'Your reply', ' DIAMOND')
    }

  }


  return (<>

    <header className={'header ' + className}>

      <div className="container">
        <div className="row header_row">
          <div className="col left_col">
            <div className="header_left">
              <a href="/">
                <p>Daily Market</p>
                <p>EXCHANGE</p>
              </a>
            </div>
          </div>

          <div className="col middle_col">
            <div className="header_middle">
              <div className="search" style={{ visibility: search ? 'hidden' : 'visible' }}>
                <i className="fa fa-search"></i><span>Search</span>
              </div>
              <div className="enter">
                <input type="text" name="search" id="" onChange={inputHandler} />
              </div>
              <div className="search_click" onClick={searchHandler}>
                <i className="fa fa-search"></i>
              </div>
            </div>

          </div>

          <div className="col right_col">
            <div className="notification_icon">
              <div className="notification_badge" onClick={notificationHandler} ref={notifyRef}>
                <span className='bell'>
                  <i className="fa fa-bell"></i>
                  {(notificationLength > 0 && load) &&
                    <span className='text badge badge-danger'>{notificationLength}</span>
                  }
                  {openNotification &&
                    <InfiniteScroll pageStart={0}
                      loadMore={findItem}
                      hasMore={hasMore}
                      initialLoad={false}>
                      <div className="notfication_list">

                        {notification.map(item => {
                          return (
                            <div className="notification_item">
                              {notificationType(item)}
                            </div>
                          )
                        })}
                        {(notification.length === 0 && user.userId) &&
                          <div className="notification_item">
                            <a> <p>0 new notifications</p></a>
                          </div>
                        }
                        {(notification.length === 0 && !user.userId) &&
                          <div className="notification_item">
                            <p><Link to='/signin'>Login to view notifications</Link></p>
                          </div>
                        }
                      </div>
                    </InfiniteScroll>
                  }
                </span>
              </div>

            </div>

            <div className="header_right right">
              {!bar && <i className="fa fa-bars" onClick={showMenu}></i>}
              {(bar && !user.userId) && <a className='header_btn' href='/signin'>Sign In</a>}
              {(bar && user.userId) && <a className='header_btn' onClick={logout}>Logout</a>}

            </div>
          </div>
        </div>
        <div className="bottom_search">
          <div className="header_middle">
            <div className="search" style={{ visibility: search ? 'hidden' : 'visible' }}>
              <i className="fa fa-search"></i><span>Search</span>
            </div>
            <div className="enter">
              <input type="text" name="" id="" onChange={inputHandler} />

            </div>
            <div className="search_click" onClick={searchHandler}>
              <i className="fa fa-search"></i>
            </div>

          </div>
        </div>
      </div>
    </header>

    <div className="menu_section">
      <div className="container">
        <div className="row">
          {mobile &&
            <div className="col-lg-3 menu_col" ref={ref}>
              <div className="menu">
                <div className="menu_box">
                  <div className={router.pathname === '/' ? "menu_row active" : "menu_row"}>
                    <img src="/home.png" alt="" /> <Link to="/">Home</Link>
                  </div>
                  <div className={router.pathname === '/about' ? "menu_row active" : "menu_row"}>
                    <img src="/about.png" alt="" /> <Link to="/about">About</Link>
                  </div>
                  {/* <div className={router.pathname === '/trending' ? "menu_row active" : "menu_row"}>
                    <img src="trending.png" alt="" /> <a href="/trending">Trending</a>
                  </div> */}
                  <div className={router.pathname === '/recent' ? "menu_row active" : "menu_row"}>
                    <img src="/recent.png" alt="" /> <Link to="/recent">Most Recent</Link>
                  </div>


                  <div className={router.pathname === '/glory' ? "menu_row active" : "menu_row"}>
                    <img src="/recent.png" alt="" /> <Link to="/glory">Halls of Glory</Link>
                  </div>

                  <div className={router.pathname === '/trades' ? "menu_row active" : "menu_row"}>
                    <img src="/recent.png" alt="" /> <Link to="/trades">Current Trades</Link>
                  </div>

                  {user.userId && <>
                    <div className={router.pathname === '/post' ? "menu_row active" : "menu_row"}>
                      <img src="/recent.png" alt="" /> <Link to="/post">My Post</Link>
                    </div>

                    <div className={router.pathname.includes('/profile/') ? "menu_row active" : "menu_row"}>
                      <img src="/recent.png" alt="" /> <Link to={"/profile/" + user.userId}>Profile</Link>
                    </div>
                  </>}

                  {(!bar && !user.userId) && <div className="menu_row">
                    <img src="/recent.png" alt="" /> <Link to="/signin">Sign In</Link>
                  </div>}
                  {(!bar && user.userId) && <div className="menu_row">
                    <img src="/recent.png" alt="" /> <a onClick={logout}>Logout</a>
                  </div>}
                  {(width < 992 && tags.length > 0) && <>
                    <p className='menu_row tag_row tag_title'>TAGS</p>
                    {

                      tags.map(item => {
                        if (item && item.trim()) {
                          return (
                            <div className="menu_row tag_row">
                              <a href={"/tags/" + item.trim().split(' ').join('-')}>{item}</a>
                            </div>
                          )
                        }
                      })
                    }
                  </>}
                </div>
              </div>

              {(width > 991 && tags.length > 0) &&
                <div className="tag_box">
                  <p className='tag_title'>TAGS</p>
                  {tags.map(item => {
                    if (item && item.trim()) {
                      return (
                        <a href={"/tags/" + item.trim().split(' ').join('-')}>{item}</a>
                      )
                    }
                  })}
                </div>
              }
            </div>
          }

          {children}


        </div>
      </div>
    </div>
  </>);
}

export default Header;